/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, {
  useContext,
} from "react";
import _ from 'lodash'
import { isBrowser, isMobile } from "react-device-detect";
import BookNowModal from "../components/BookNowModal";

import { ContextServiceApi } from "../context/ContextServiceApi";
import { MainLayout } from "../components/layouts/MainLayout";
import { DestinationFeature } from "../components/DestinationFeature";
import { SignatureTourFeature } from "../components/SignatureTourFeature";
import ImageGallery from "../components/ImageGallery";

const metrics = [
  {
    id: 1,
    stat: "8K+",
    emphasis: "Companies",
    rest: "use laoreet amet lacus nibh integer quis.",
  },
  {
    id: 2,
    stat: "25K+",
    emphasis: "Countries around the globe",
    rest: "lacus nibh integer quis.",
  },
  {
    id: 3,
    stat: "98%",
    emphasis: "Customer satisfaction",
    rest: "laoreet amet lacus nibh integer quis.",
  },
  {
    id: 4,
    stat: "12M+",
    emphasis: "Issues resolved",
    rest: "lacus nibh integer quis.",
  },
];



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let YTVideoPanel = () =>
  isBrowser && (
    <section className="w-[600px] h-full relative">
      <div className="absolute h-full w-full" />

      <div className="absolute top-0 h-[100px] w-full bg-black" />
      <div className="absolute bottom-0 h-[100px] w-full bg-black" />
      <iframe
        className="w-full h-full"
        title="yt-embeded"
        src="https://www.youtube.com/embed/j5GH_rKy1PQ?controls=0&autoplay=1&mute=1&playsinline=1&playlist=j5GH_rKy1PQ&loop=1&start=8"
      ></iframe>
    </section>
  );

let HeroSection = () => (
  <div
    className="relative bg-no-repeat bg-cover bg-black"
    style={{
      backgroundImage: `${isMobile && "url(/assets/images/img_5.jpg)"}`,
    }}
  >
    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100/0" />
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 backdrop-blur-sm">
      <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
        <div className="hidden absolute inset-2">
          <div className="absolute inset-0 bg-gradient-to-r from-green-200 to-yellow-200 mix-blend-multiply" />
        </div>
        <div className={`${isBrowser && "grid sm:grid-cols-2"}`}>
          <div className="colspan-1 relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">Tour Jamaica </span>
              <span className={`block ${isBrowser ? 'text-red-500':'text-black'}`}>know Jamaica</span>
            </h1>
            <p className="mx-auto mt-6 max-w-lg text-center text-xl font-medium text-sky-100 sm:max-w-3xl bg-black/40 rounded-lg">
              Join us on an adventure to some of the most spectacular spots on
              the island.
            </p>
            <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
              <div className="invisible space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                <BookNowModal />
              </div>
            </div>
          </div>
          <div className="colspan-1 h-full ">
            <YTVideoPanel />
          </div>
        </div>
      </div>
    </div>
  </div>
);

let LogoCloud = () => (
  <div className="hidden bg-gray-100">
    <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
      <p className="text-center text-base font-semibold text-gray-500">
        Trusted by over 5 very average small businesses
      </p>
      <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
            alt="Tuple"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg"
            alt="Mirage"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
            alt="StaticKit"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
            alt="Transistor"
          />
        </div>
        <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
          <img
            className="h-12"
            src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
            alt="Workcation"
          />
        </div>
      </div>
    </div>
  </div>
);




let AltFeatureSection = () => (
  <div className="relative overflow-hidden pt-16 pb-4">
    <div className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-sky-200/0" />
    <div className="relative">
      {/* <div className="grid grid-flow-row-dense auto-rows-min grid-rows-2 grid-cols-2  sm:grid-rows-1 max-w-7xl lg:gap-24 lg:px-8"> */}
      <div className="mx-auto sm:flex md:grid grid-flow-row-dense auto-rows-min grid-rows-2 grid-cols-2  sm:grid-rows-1 max-w-7xl lg:gap-24 lg:px-8">
        <SignatureTourFeature/>
        <ImageGallery />
      </div>
    </div>
  </div>
);



export default function HomePage() {
  const { location } = useContext(ContextServiceApi);
  return (
    <MainLayout>
      <div className={`${isMobile ? "w-fit" : "w-full"} bg-white pt-20`}>


        <section>
          {/* Hero section */}
          <HeroSection />
          {/* Logo Cloud */}
          <LogoCloud />

          {/* Alternating Feature Sections */}
          <AltFeatureSection />
          {/* CTA Section */}
          <div className="bg-white">
            <div className="mx-auto max-w-4xl py-16 px-4 sm:px-6 sm:py-24 lg:flex lg:max-w-7xl lg:items-center lg:justify-between lg:px-8">
              <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Ready to tour?</span>
                <span className="-mb-1 block bg-gradient-to-r from-sky-600 to-green-600 bg-clip-text pb-1 text-transparent">
                  Get in touch or create an experience.
                </span>
              </h2>
              <div className="!hidden mt-6 space-y-4 sm:flex sm:space-y-0 sm:space-x-5">

                <a
                  href="#"
                  className="flex items-center justify-center rounded-md border border-transparent bg-green-50 px-4 py-3 text-base font-medium text-green-800 shadow-sm hover:bg-green-100"
                >
                  Start Your Next Tour
                </a>
              </div>
            </div>
          </div>
          {/* Negril Feature Section */}
          <DestinationFeature place={location.negril} />
          {/* MontegoBayFeature Feature Section */}
          <DestinationFeature place={location.mobay} />
          {/* OchiRiosFeature Feature Section */}
          <DestinationFeature place={location.ochi} />
          {/* PortLandFeature Feature Section */}
          <DestinationFeature place={location.portland} />
          {/* StElizabethFeature Feature Section */}
          <DestinationFeature place={location.stelizabeth} />
          {/* FalmouthFeature Feature Section */}
          <DestinationFeature place={location.falmouth} />

          {/* Stats section */}
          <div className="relative bg-gray-900">
            <div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full w-full">
              <div className="h-full w-full xl:grid xl:grid-cols-2">
                <div className="h-full w-full xl:relative xl:col-start-2">
                  <img
                    className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                    src="/assets/images/img_5.jpeg"
                    alt="People working on laptops"
                  />
                </div>
              </div>
            </div>
            <div
              className="bg-fixed bg-no-repeat bg-cover mx-auto   xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8"
              style={{ backgroundImage: `url(../assets/images/jam_img_1.jpeg)` }}
            >
              <div className="backdrop-blur-lg bg-black/10 relative px-4 sm:px-6 lg:px-8 pt-12 pb-64 sm:pt-24 sm:pb-64 col-span-2 xl:pb-24">
                <h2 className="text-base font-semibold">
                  <span className="bg-gradient-to-r from-sky-300 to-green-300 bg-clip-text text-transparent">
                    More About Jamaica
                  </span>
                </h2>
                <p className="mt-3 text-3xl font-bold tracking-tight text-white">
                  The Vibes That Comes Alive In Jamaica
                </p>
                <p className="mt-5 text-lg text-white">
                  There is little doubt that Brand Jamaica has captured the hearts
                  and minds of travellers across the globe. Rich cultural
                  heritage, warm, friendly people, outstanding gastronomy,
                  riveting sounds of the one drop reggae beat, world class
                  athletics and beautiful land and seascapes are just a few of the
                  things that come to mind when you think about Jamaica.
                </p>
                <div className="hidden mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                  {metrics.map((item) => (
                    <p key={item.id}>
                      <span className="block text-2xl font-bold text-white">
                        {item.stat}
                      </span>
                      <span className="mt-1 block text-base text-gray-300">
                        <span className="font-medium text-white">
                          {item.emphasis}
                        </span>{" "}
                        {item.rest}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
    </MainLayout>
  );
}
