import React, {
  Component,
  useContext,
  useEffect,
  Fragment,
  useState,
} from "react";
import _ from 'lodash'
import { Link } from "react-router-dom";
import {
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function HeroMode(props) {
  return (
    <div className="relative overflow-hidden bg-white pt-24">
      <div className="mx-auto max-w-7xl">
        <div className="grid xs:grid-cols-1 md:grid-cols-2  relative bg-white p-8  w-full ">


          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">{props.place?.title}</span>{' '}
              <span className="block text-indigo-600 xl:inline">Excursions</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
              {props.place?.description}
            </p>


          </div>


          <div className="w-full h-full  bg-white">

            <Carousel axis="horizontal" autoPlay infiniteLoop showThumbs={false} showArrows={false} stopOnHover={false} swipeable={false}>
              {props.place?.attractions.map((attraction) => (
                <div
                  key={attraction.name}
                  className="grid bg-cover bg-center bg-slate-900 bg-no-repeat p-6 shadow-xl rounded-lg h-[400px] place-items-center"
                  style={{ backgroundImage: `url(${attraction.images[0]})` }}
                >
                  <div className="mt-6 text-white  rounded bg-black/10 p-1 w-fit">
                    <h3 className="text-lg font-extrabold">{attraction.name}</h3>
                  </div>
                </div>
              ))}

            </Carousel>
          </div>
        </div>
      </div>


    </div>
  )
}

export function SignatureTourFeature(props) {

  let nav_el = <a
    key="signatureTour"
    href="/signatureTour"
    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
  >
    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md
      bg-cover bg-gradient-to-r from-sky-600 to-green-600 text-white sm:h-12 sm:w-12"
      style={{ backgroundImage: `url(/assets/images/img_1.jpg)` }} />
    <div className="ml-4 relative">
      <p className="text-base font-medium text-gray-900">Signature Tour
      </p>
      <p className="mt-1 text-sm text-gray-500 h-12 overflow-hidden">Meet and experience authentic people in a vivid Jamaica.
      </p>
      <div className="h-6 w-full absolute bottom-0 right-0 bg-gradient-to-b from-white/30 to-white" />
    </div>
  </a>

  let el = <div className="col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-1 mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
    <div>
      <div className="mt-6">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
          Signature Tours
        </h2>
        <p className="mt-4 text-lg text-gray-500">
          The Jamaican lifestyle is one that is fun-filled and creative in every
          aspect. Here is your chance to learn and experience a deeper look into
          things that are a part of our culture and lifestyle such as; Ackee, Sugar
          Cane, patois, reggae music and much more. Jamaica on a
          whole is a place where you will always find something fun to see/do
          and explore to learn more about our lifestyle and feel a part of it.
        </p>
      </div>
    </div>
    <div className="!hidden bg-gradient-to-r from-green-200 to-yellow-200 rounded-lg p-3 mt-8 border-t border-gray-200 pt-6">
      <blockquote>
        <div>
          <p className="text-base text-gray-500">
            &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam.
            Sit orci risus aenean curabitur donec aliquet. Mi venenatis in
            euismod ut.&rdquo;
          </p>
        </div>
        <footer className="mt-3">
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              <img
                className="h-6 w-6 rounded-full"
                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                alt=""
              />
            </div>
            <div className="text-base font-medium text-gray-700">
              Marcia Hill, Digital Marketing Manager
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  </div>

  let name_el =
    <li key={props.place?.title}>
      <a

        href={props.place?.link}
        className="text-base text-gray-500 hover:text-gray-900"
      >
        {props.place?.title}
      </a>
    </li>

  let hero_el = <>
    <HeroMode place={props.place} />
  </>

  let elem = el
  if (_.has(props, 'nav')) {
    elem = nav_el;
  }
  if (_.has(props, 'nameOnly')) {
    elem = name_el;
  }
  if (_.has(props, 'hero')) {
    elem = hero_el;
  }
  return elem
};